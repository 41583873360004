import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ListeActualites from "../components/pages/Composants/ListeActualites"
import CategoryFilter from "../components/Navigation/CategoryFilter"
import PageHeaderNoIllustration from "../components/pages/PageHeaderNoIllustration"
import PaginationPublications from "../components/Navigation/PaginationPublications"

const PublicationsListTemplate = ({ data, pageContext }) => {

  const allPublications = data.allStrapiPublication.nodes

  const { currentPage, numPages } = pageContext
  const slugCategory = pageContext.category || "publications"
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/${pageContext.locale}/${slugCategory}` : `/${pageContext.locale}/${slugCategory}/${(currentPage - 1).toString()}`
  const nextPage = `/${pageContext.locale}/${slugCategory}/${(currentPage + 1).toString()}`


  return (
    <Layout>
      <PageHeaderNoIllustration title={"Actualités"} />
      <div className="container mx-auto py-12">
        <CategoryFilter currentCategory={slugCategory} />
      </div>
      <ListeActualites publications={allPublications} />
      <PaginationPublications
        category={slugCategory}
        locale={pageContext.locale || "fr"}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
        isFirst={isFirst}
        isLast={isLast}
      />
      <div dangerouslySetInnerHTML={{ __html: `<!-- ♥️ Cyp -->` }} />
    </Layout>
  )
}

export default PublicationsListTemplate

export const query = graphql`
  query allPublications(
    $skip: Int!
    $limit: Int!
    $category: String
    $locale: String
  ) {
    allStrapiPublication(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
      filter: {locale: {eq: $locale}, categorie: { slug: { eq: $category } } }
    ) {
      nodes {
        childStrapiPublicationContenuTextnode {
          childMarkdownRemark {
            html
          }
        }
        titre
        description
        slug
        soustitre
        updatedAt
        publishedAt
        publishedAtURL: publishedAt(formatString: "YYYY/MM/DD")
        publishedAtHumanized: publishedAt(
          formatString: "ddd DD MMMM YYYY"
          locale: "fr"
        )
        locale
        categorie {
          slug
          nom
          locale
        }
        contenu {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        illustration {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      totalCount
    }
  }
`
