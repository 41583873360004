import { useStaticQuery, graphql } from "gatsby"

export const useAllCategories = () => {
  const { allStrapiCategorie } = useStaticQuery(graphql`
    query allCategoriesHook {
      allStrapiCategorie {
        nodes {
          locale
          nom
          slug
        }
      }
    }
  `)
  return allStrapiCategorie
}
