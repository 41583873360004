import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const PaginationPublications = ({ prevPage, isFirst, currentPage, numPages, isLast, nextPage, category, locale }) => {
  return (
    <div className="mt-5 flex justify-center space-x-2">
      {!isFirst && (
        <Link className="btn-page" to={`${prevPage}`} rel="prev">
          ← Previous Page
        </Link>
      )}
      {Array.from({ length: numPages }, (_, i) => {
        if (i === currentPage - 1) {
          return (
            <div
              className="bg-red-sesf px-2 py-0.5 font-bold text-white"
              key={`pagination-number${i + 1}`}
            >
              {i + 1}{" "}
            </div>
          )
        } else {
          return (
            <Link
              className="px-2 py-0.5 font-bold"
              key={`pagination-number${i + 1}`}
              to={`/${locale}/${category}${i === 0 ? "" : "/" + (i + 1)}`}
            >
              {i + 1}
            </Link>
          )
        }
      })}
      {!isLast && (
        <Link className="btn-page" to={nextPage} rel="next">
          Next Page →
        </Link>
      )}
    </div>
  );
};

PaginationPublications.propTypes = {

};

export default PaginationPublications;