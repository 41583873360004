import React from "react"
import PropTypes from "prop-types"
import { useAllCategories } from "../../hooks/use-all-categories"
import { Link } from "gatsby"
import classNames from "classnames"

const CategoryFilter = ({ currentCategory }) => {
  const allCategories = useAllCategories()
  const toutClasses = classNames("font-bold px-2 py-0.5 border-2 border-ehsl-purple-800", {
    "bg-white text-ehsl-purple-800": currentCategory === "publications",
    "bg-ehsl-purple-800 text-white": currentCategory !== "publications",
  })

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap gap-4">
        <div>Type de contenu : </div>
        <Link
          className={toutClasses}
          title={"Toutes les publications"}
          to={`/fr/publications`}
        >
          Tout
        </Link>
        {allCategories.nodes.map(category => {
          const classesLink = classNames("font-bold px-2 py-0.5 border-2 border-ehsl-purple-800", {
            "bg-white text-ehsl-purple-800": currentCategory === category.slug,
            "bg-ehsl-purple-800 text-white": currentCategory !== category.slug,
          })
          return (
            <Link
              className={classesLink}
              title={category.nom}
              to={`/fr/${category.slug}`}
            >
              {category.nom}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

CategoryFilter.propTypes = {}

export default CategoryFilter
